import React from 'react';
import './SocialMedia.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faYoutube, faWhatsapp, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import Footer from './Footer';

const SocialMedia = () => {
  const linkStyles = {
    textDecoration: 'none',
    color: 'white',
};
  return (
    <div className='SMBg1'>
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>Know More</h2>
            <ul>
            <li>
                <Link to={"/"}  style={linkStyles}>Home</Link>
            </li>
            <li>
                <Link to={"/AboutUs" } style={linkStyles}>About Us</Link>
            </li>
            <li>
                <Link to={"/Services"} style={linkStyles}>Services</Link>
            </li>
            <li>
                <Link to={"/contact"} style={linkStyles}>Contact Us</Link>
            </li>
        </ul>


          </div>
          <div className="col">
            <h2>Legal</h2>
            <ul>
              <li>Privacy Policy</li>
              <li>Terms & Conditions</li>
              <li>Return Policy</li>
            </ul>
          </div>
          
          <div className="col">
            <h2>Address</h2>
            <ul>
              <li>1-964 4th floor, teachers colony, near by D-mart & Royal show room,bangalore Highway, Anantapur</li>
            </ul>
          </div>
          
        </div>
      </div>

      <div className="container">
        <hr style={{ borderColor: 'white' }} />
        <h3 className="text-white container">Contact us through</h3>
        <br/>
        <ul className="wrapper">
          {/* <a href="https://facebook.com" style={{ color: 'blue' }}>
            <li className="icon facebook">
              <span className="tooltip">Facebook</span>
              <span><FontAwesomeIcon icon={faFacebookF} /></span>
            </li>
          </a> */}
          <a href="https://whatsapp.com/channel/0029VaTSRnqGJP8KBBQ3gn3n" style={{ color: 'green' }} target="_blank">
    <li className="icon whatsapp">
        <span className="tooltip">WhatsApp</span>
        <span><FontAwesomeIcon icon={faWhatsapp} /></span>
    </li>
</a>
<a href="https://www.instagram.com/ahiraalearning_pvtltd?igsh=N3h6Nm50cm4zeG9u" style={{ color: 'red' }} target="_blank">
    <li className="icon instagram">
        <span className="tooltip">Instagram</span>
        <span><FontAwesomeIcon icon={faInstagram} /></span>
    </li>
</a>
<a href="https://youtube.com" style={{ color: 'red' }} target="_blank">
    <li className="icon youtube">
        <span className="tooltip">YouTube</span>
        <span><FontAwesomeIcon icon={faYoutube} /></span>
    </li>
</a>
<a href="mailto:ahiraalearning.helpline@gmail.com" style={{ color: '#3255a5' }} target="_blank">
    <li className="icon gmail">
        <span className="tooltip">Gmail</span>
        <span><FontAwesomeIcon icon={faGoogle} /></span>
    </li>
</a>

        </ul>
        <p className="bg-black text-center py-3 mt-4 text-white ">© 2024 Ahiraa Learning Pvt Ltd. <br/>Developed by Muddangula Ashok.</p>

      </div>
      
      {/* <footer className="footer text-center mt-4">
        <p>© 2024 Ahiraa Learning. Developed by Muddangula Ashok.</p>
      </footer> */}
      {/* <Footer/> */}

      {/* <footer >
  <p className="mb-0">© 2024 Ahiraa Learning. Developed by Muddangula Ashok.</p>
</footer> */}
    </div>
  );
};

export default SocialMedia;
