import React from 'react';
import './Offers.css';

function Offers() {
  return (
    <div className='Offers'>
      <div id="section1" className="container-fluid p-3 bg-lightpink container">
        <h2 className="text-center my-4">We are providing our best to student</h2>
        <div className="row">
          <div className="col-12 col-md-6 col-sm-6 mb-4">
            <div className="p-1">
              <h2 className='Offerh2'>Live Training Sessions</h2>
              <p> We provide comprehensive live training sessions complemented by recorded classes for continuous learning.</p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-6 mb-4">
            <div className="p-1">
              <h2>Distinguished Mentors</h2>
              <p>Participate in in-depth discussions and receive guidance from esteemed mentors to resolve your queries.</p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-6 mb-4">
            <div className="p-1">
              <h2>Internship Experience</h2>
              <p>Acquire a robust blend of theoretical insights and practical skills through our immersive internship programs.</p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-6 mb-4">
            <div className="p-1">
              <h2>Industry Relevant Projects</h2>
              <p>Learn directly from industry experts as they guide you through hands-on projects that mirror real-world scenarios.</p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-6 mb-4">
            <div className="p-1">
              <h2>LMS Access</h2>
              <p>we're will tring to provide LSM access</p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-6 mb-4">
            <div className="p-1">
              <h2>Professional Certifications</h2>
              <p>We're providing Certifications based on training & internship</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container p-4">
        <h3>Obtain professional certifications upon successful completion of your training and internship, validating your expertise and achievements.</h3>
      </div>
    </div>
  );
}

export default Offers;
