import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './DetailsPage.css';
import { Modal, Button } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const DetailsPage = () => {
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneCountryCode, setPhoneCountryCode] = useState(''); // State to store phone country code
    const [email, setEmail] = useState('');
    const [college, setCollege] = useState('');
    const [errors, setErrors] = useState({});
    const [formSubmissionStatus, setFormSubmissionStatus] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();
    const formRef = useRef(null);

    const validateForm = () => {
        const newErrors = {};
        if (!name.trim()) newErrors.name = 'Name is required.';

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) newErrors.email = 'Email is invalid.';

        if (!college.trim()) newErrors.college = 'College name is required.';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        setFormSubmissionStatus(null);

        if (validateForm()) {
            const formData = {
                name,
                phoneNumber,
                phoneCountryCode, // Include phone country code in the formData
                email,
                college,
            };

            fetch('/backend/save_form.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                if (data.success) {
                    setShowModal(true);
                    formRef.current.reset(); // Optionally reset the form after successful submission
                } else {
                    setFormSubmissionStatus('error');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setFormSubmissionStatus('error');
            });
        } else {
            setFormSubmissionStatus('error');
        }
    };

    return (
        <div className="details-container">
            <div className="details-content">
                <h2>Take More Advice from Our Team</h2>
                <p>Fill in the form below to receive personalized advice from our team:</p>
                <form ref={formRef} onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Name:</label>
                        <input
                            className="form-control"
                            type="text"
                            value={name}
                            placeholder="Ex: Your Name"
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                        {errors.name && <p className="error">{errors.name}</p>}
                    </div>
                    <div className="form-group">
                        <label>Phone Number:</label>
                        <PhoneInput
                            country={'in'}
                            value={phoneNumber}
                            onChange={(value, country) => {
                                setPhoneNumber(value);
                                setPhoneCountryCode(country.dialCode); // Update phone country code state
                            }}
                            inputProps={{
                                name: 'phone',
                                required: true
                            }}
                            containerClass="form-control"
                            inputClass="w-100"
                        />
                    </div>
                    <div className="form-group">
                        <label>Email:</label>
                        <input
                            className="form-control"
                            type="email"
                            value={email}
                            placeholder="Ex: example123@gmail.com"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        {errors.email && <p className="error">{errors.email}</p>}
                    </div>
                    <div className="form-group">
                        <label>College Name:</label>
                        <input
                            className="form-control"
                            type="text"
                            value={college}
                            placeholder="Your College Name"
                            onChange={(e) => setCollege(e.target.value)}
                            required
                        />
                        {errors.college && <p className="error">{errors.college}</p>}
                    </div>
                    <button className="btn btn-primary" type="submit">Submit</button>
                </form>

                {formSubmissionStatus === 'error' && (
                    <p style={{ color: 'red', textAlign: 'center' }}>Please enter the correct details.</p>
                )}

                <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Success</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Your data has been successfully submitted! Our team will call you shortly. You can also opt for a free coding session with our team.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => {
                            setShowModal(false);
                            navigate('/');
                        }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default DetailsPage;
