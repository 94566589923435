import React from 'react';
import './Home.css';
import Content from './Content';
import Provider from './Provider';
import Subjects from './Subjects';
import SocialMedia from './SocialMedia';
import Tieup from '../Colab/Tieup';
// import Collage from './Collage/Collage';
import { Link } from 'react-router-dom';
import Marquee from './Marquee/Marquee';
import { Navbar, Nav } from 'react-bootstrap';
import DetailsPage from './DetailsPage';
import AnimatedCounters from './AnimatedCounters';



function Home() {
    return (
        <div>
             <Navbar expand="lg " className='Hnavbar'>
    <div className="container d-flex align-items-center"> {/* Added d-flex and align-items-center */}
        {/* Brand */}
        <div className="d-flex align-items-center"> {/* Added d-flex and align-items-center */}
            <img src="./LogoHead.png" alt="Brand Logo" style={{ borderRadius: '150px', height: '33px', width: '32px' }} />
            <Link className="navbar-brand" style={{color:'white', padding:'12px'}} to="/">Ahiraa Learning  Pvt.Ltd</Link>
         
        
        {/* Navbar Toggle */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ color: 'white', backgroundColor:'white' }}>
      <span className="navbar-toggler-icon" style={{ backgroundColor: 'white' }} />
    </Navbar.Toggle>      </div>
        
        {/* Navbar Collapse */}
        <Navbar.Collapse id="basic-navbar-nav" style={{color:'white'}}>
        <Nav className="ms-auto">
                        <Link className="nav-link-custom" to="/AboutUs">About</Link>
                        <Link className="nav-link-custom" to="/Services">Service</Link>
                        <Link className="nav-link-custom" to="/LMS">LMS</Link>
                        <Link className="nav-link-custom" to="/contact">Contact</Link>
                        <Link className="nav-link-custom" to="https://forms.gle/bc8W38kTRHxgT44W6"  target="_blank" rel="noreferrer">IND Student Form</Link>
                        <Link className="nav-link-custom" to="https://forms.gle/MuwuwTTHBMPJeTCE7"  target="_blank" rel="noreferrer">International Student Form</Link>
                        {/* <strong>or <a className="payment-issue-email-link" href="https://forms.gle/KeQaq3RXEntGNQCs6" target="_blank" rel="noreferrer">Fill the Google Form (Click Me)</a>
          </strong> */}
                    </Nav>
        </Navbar.Collapse>
    </div>
</Navbar>




            
            {/* Content sections */}
            <Content />

            <Marquee />
            <Provider />
            <Subjects />
            <AnimatedCounters/>
           
            {/* <Dummy /> */}
            {/* <Collage /> */}
            {/* <Dummy /> */}
            <DetailsPage/>
            {/* <Tieup /> */}
            {/* <Marquee1/> */}
            <SocialMedia />
        </div>
    );
}

export default Home;
