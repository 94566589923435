import React from 'react';
import { Link } from 'react-router-dom';
import './PageIsEmpty.css'; // Import your external CSS file

function PageIsEmpty() {
  return (
    <div className="page-is-empty-container">
      <div className="page-is-empty-text">
        Doesn't have any page, please go back to the home page.
      </div>
      <Link to={'/'} className="link-to-home">Go Back to Home Page</Link>
    </div>
  );
}

export default PageIsEmpty;
