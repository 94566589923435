import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        // This function will run when the pathname changes
        console.log('Route changed to:', pathname);
        window.scrollTo(0, 0);
    }, [pathname]); // Dependency array with `pathname` to run the effect on route change

    // This component doesn't render anything
    return null;
}

export default ScrollToTop;
