import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './Marquee.css'; // Import your custom CSS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGithub,
  faCodepen,
  faFreeCodeCamp,
  faDev,
  faReact,
  faVuejs,
  faAngular,
  faNode,
  faWordpress,
  faAws,
  faDocker,
  faAndroid,
  faJava,
  faPython,
  faHtml5,
  faCss3Alt,
  faBootstrap,
  faPhp,
  faSwift,
  faJenkins,
  faLaravel,
  faJira,
  faRedhat,
  faRaspberryPi,
} from '@fortawesome/free-brands-svg-icons';

function Marquee() {
  const marqueeContentRef = useRef(null);

  useEffect(() => {
    const root = document.documentElement;
    const marqueeElementsDisplayed = parseInt(getComputedStyle(root).getPropertyValue("--marquee-elements-displayed"), 10);
    const marqueeContent = marqueeContentRef.current;

    root.style.setProperty("--marquee-elements", marqueeContent.children.length);

    for (let i = 0; i < marqueeElementsDisplayed; i++) {
      marqueeContent.appendChild(marqueeContent.children[i].cloneNode(true));
    }
  }, []);

  return (
    <div className='bg-dark'>
      {/* <div className='pt-3 bg-dark rounded'>*/}
      <div className='container pt-3 bg-dark rounded'> 
        <div className="marquee">
          <ul className="marquee-content d-flex align-items-center" ref={marqueeContentRef}>
            {/* Render FontAwesome icons */}
            
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            
            
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            
            
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            
            
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>


            
            
            
            
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>


            
            
            
            
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faGithub} className= 'icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCodepen} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faFreeCodeCamp} className='icon'  style={{ color: '#006400' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDev} className='icon' style={{ color: '#000000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faReact} className= 'icon' style={{ color: '#61DBFB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faVuejs} className= 'icon' style={{ color: '#4FC08D' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAngular} className= 'icon' style={{ color: '#DD0031' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faNode} className= 'icon' style={{ color: '#68A063' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faWordpress} className= 'icon' style={{ color: '#21759B' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAws} className= 'icon' style={{ color: '#FF9900' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faDocker} className= 'icon' style={{ color: '#0DB7ED' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faAndroid} className= 'icon' style={{ color: '#3DDC84' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJava} className= 'icon' style={{ color: '#007396' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPython} className= 'icon' style={{ color: '#3776AB' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faHtml5} className= 'icon' style={{ color: '#E34F26' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faCss3Alt} className= 'icon' style={{ color: '#1572B6' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faBootstrap} className= 'icon' style={{ color: '#7952B3' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faPhp} className= 'icon' style={{ color: '#8892BF' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faSwift} className= 'icon' style={{ color: '#FA7343' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJenkins} className= 'icon' style={{ color: '#D24939' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faLaravel} className= 'icon' style={{ color: '#FF2D20' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faJira} className= 'icon' style={{ color: '#0052CC' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRedhat} className= 'icon' style={{ color: '#EE0000' }} /></li>
            <li className="mx-3"><FontAwesomeIcon icon={faRaspberryPi} className= 'icon' style={{ color: '#C51A4A' }} /></li>


            
            
          </ul>
        </div>
      </div>
    </div>   
  );
}

export default Marquee;
