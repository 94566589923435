import React from 'react';
import Subjects from './Subjects';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SocialMedia from './SocialMedia';

function Services() {
  return (
    <div>
      <Navbar expand="lg " className='Hnavbar'>
    <div className="container d-flex align-items-center"> {/* Added d-flex and align-items-center */}
        {/* Brand */}
        <div className="d-flex align-items-center"> {/* Added d-flex and align-items-center */}
            <img src="./LogoHead.png" alt="Brand Logo" style={{ borderRadius: '150px', height: '33px', width: '32px' }} />
            <Link className="navbar-brand" style={{color:'white', padding:'12px'}} to="/">Ahiraa Learning  Pvt.Ltd</Link>
         
        
        {/* Navbar Toggle */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ color: 'white', backgroundColor:'white' }}>
      <span className="navbar-toggler-icon" style={{ backgroundColor: 'white' }} />
    </Navbar.Toggle>        </div>
        
        {/* Navbar Collapse */}
        <Navbar.Collapse id="basic-navbar-nav" style={{color:'white'}}>
        <Nav className="ms-auto">
                        <Link className="nav-link-custom" to="/">Home</Link>
                        <Link className="nav-link-custom" to="/AboutUs">About</Link>
                        <Link className="nav-link-custom" to="/Services">Service</Link>
                        <Link className="nav-link-custom" to="/LMS">LMS Login</Link>
                        <Link className="nav-link-custom" to="/contact">Contact</Link>
                        <Link className="nav-link-custom" to="https://forms.gle/KeQaq3RXEntGNQCs6"  target="_blank" rel="noreferrer">Student Form</Link>

                    </Nav>
        </Navbar.Collapse>
    </div>
</Navbar>
      <Subjects/>
      <SocialMedia/>
    </div>
  )
}

export default Services
