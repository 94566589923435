import React, { useState, useEffect } from 'react';
import './PaymentProcessingIssue.css';

const PaymentProcessingIssue = ({ email }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Set a timeout for 2 seconds to simulate loading
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    // Cleanup the timeout on component unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="payment-issue-container">
      {loading ? (
        <div className="loading-spinner">
          {/* You can replace this with an actual spinner/loader */}
          Loading for Payment...
        </div>  
      ) : (
        <p className="payment-issue-message">
          <h4>Sorry :(</h4>
          We regret to inform you that due to technical difficulties, we are unable to process payments at this time. We apologize for any inconvenience this may cause.
          <br />
          To provide your details for further assistance, please use the following email address:<br/> 
          <a href="mailto:ahiraalearning.helpline@gmail.com" className="payment-issue-email-link">
            <strong>ahiraalearning.helpline@gmail.com </strong>
          </a><br/>
          <strong>or <a className="payment-issue-email-link" href="https://forms.gle/KeQaq3RXEntGNQCs6" target="_blank" rel="noreferrer">Fill the Google Form (Click Me)</a>
          </strong>
          <br/>
          Please include the following details:<br/> <strong> Name, Phone Number, College Name and Your Requirement </strong>
          <br/>
          We assure you that we will promptly reach out to you within a few minutes.
        </p>
      )}
    </div>
  );
};

export default PaymentProcessingIssue;
