import React from 'react';
import { Link } from 'react-router-dom';
import SocialMedia from '../Home/SocialMedia';
import './Coding.css';
import '../Home/Subject.css'; // Import CSS file for styling

import DetailsPage from '../Home/DetailsPage';


import java from '../Code/Images/java.png';
import python from '../Code/Images/python.png';
import c from '../Code/Images/c.jpg';
import js from '../Code/Images/js.jpg';
import css from '../Code/Images/css.jpg';
import mysql from '../Code/Images/mysql.png';
function Coding() {
  return (
    <div>
      <h3 className='Codh3'>Coding Sessions</h3>
    
      <div className='codBg3'>
      <h5 className='codh6'>
        Internships provide valuable online experiences, enabling students to develop skills, network with professionals, explore specialties, enhance resumes, and prepare for future careers, often leading to employment opportunities
      </h5>
        <div className='container'>
          <div className='row mx my-3'>
            <div className='col-md-12'>
              
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-6 col-md-4 my-2">
              <div className="card " style={{ borderRadius: '20px', overflow: 'hidden' }}>
                <Link >
                  <img src={java} className="card-img-top" alt="..." style={{ width: '100%',  height: '100%', objectFit: 'cover', transition: 'transform 0.4s' }} />
                </Link>
                <div className="card-body d-flex align-items-end" style={{ backgroundColor: 'black' }}>
                  <Link  className="btn btn-secoundry w-100 p-25"><span style={{ color: 'white', fontWeight: 'bold' }}>java</span></Link>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-4 my-2">
              <div className="card " style={{ borderRadius: '20px', overflow: 'hidden' }}>
                <Link >
                  <img src={python} className="card-img-top" alt="..." style={{ width: '100%', height: '100%', objectFit: 'cover', transition: 'transform 0.4s' }} />
                </Link>
                <div className="card-body d-flex align-items-end" style={{ backgroundColor: 'black' }}>
                  <Link  className="btn btn-secoundry w-100 p-25"><span style={{ color: 'white', fontWeight: 'bold' }}>Python</span></Link>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-4 my-2">
              <div className="card " style={{ borderRadius: '20px', overflow: 'hidden' }}>
              <Link >
                  <img src={c} className="card-img-top" alt="..." style={{ width: '100%', height: '100%', objectFit: 'cover', transition: 'transform 0.4s' }} />
                </Link>
                <div className="card-body d-flex align-items-end" style={{ backgroundColor: 'black' }}>
                  <Link className="btn btn-secoundry w-100 p-25"><span style={{ color: 'white', fontWeight: 'bold' }}>c++</span></Link>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4 my-2">
              <div className="card " style={{ borderRadius: '20px', overflow: 'hidden' }}>
                <Link >
                  <img src={css} className="card-img-top" alt="..." style={{ width: '100%',  height: '100%', objectFit: 'cover', transition: 'transform 0.4s' }} />
                </Link>
                <div className="card-body d-flex align-items-end" style={{ backgroundColor: 'black' }}>
                  <Link className="btn btn-secoundry w-100 p-25"><span style={{ color: 'white', fontWeight: 'bold' }}>HTML5,CSS3</span></Link>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-4 my-2">
              <div className="card " style={{ borderRadius: '20px', overflow: 'hidden' }}>
              <Link>
                  <img src={js} className="card-img-top" alt="..." style={{ width: '100%', height: '100%', objectFit: 'cover', transition: 'transform 0.4s' }} />
                </Link>
                <div className="card-body d-flex align-items-end" style={{ backgroundColor: 'black' }}>
                  <Link className="btn btn-secoundry w-100 p-25"><span style={{ color: 'white', fontWeight: 'bold' }}>HTML5,CSS3,JS</span></Link>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-4 my-2">
              <div className="card " style={{ borderRadius: '20px', overflow: 'hidden' }}>
                <Link >
                  <img src={mysql} className="card-img-top" alt="..." style={{ width: '100%',  height: '100%', objectFit: 'cover', transition: 'transform 0.4s' }} />
                </Link>
                <div className="card-body d-flex align-items-end" style={{ backgroundColor: 'black' }}>
                  <Link className="btn btn-secoundry w-100 p-25"><span style={{ color: 'white', fontWeight: 'bold' }}>SQL WorkBench</span></Link>
                </div>
              </div>
            </div>
           
            {/* Include other columns with similar structure */}
          </div>
        </div>
      </div>

      <div className='eeebg4'>
      <div style={{ fontFamily: 'Arial, sans-serif', maxWidth: '90%', margin: '0 auto' }}>
  <h2 style={{ fontSize: '30px', marginBottom: '20px', color:'white' }}>🌟 45-Day Online Java Course 🌟</h2>
  
  <p style={{ fontSize: '16px', marginBottom: '20px' }}>
    we are giving 45-day & more online Coding course, here's how it's structured:
  </p>
  <h3 style={{ fontSize: '24px', marginBottom: '10px' }}>We offer Three certificates:</h3>
  <ul style={{ listStyleType: 'none', padding: '0', margin: '0 0 30px 0' }}>
    <li style={{ fontSize: '16px', marginBottom: '15px' }}>
      1) Course Completion Certificate
    </li>
    <li style={{ fontSize: '16px', marginBottom: '15px' }}>
      2) Coding Skill Certificate
    </li>
    <li style={{ fontSize: '16px', marginBottom: '15px' }}>
      3) Performance Certificate
    </li>
  </ul>
  <p style={{ fontSize: '16px', marginBottom: '20px' }}>
    Visit us: <a href="http://www.ahiraalearning.com" style={{color:'white', textDecoration:'none'}}>www.ahiraalearning.com</a>
  </p>
  <p style={{ fontSize: '16px', marginBottom: '20px' }}>
    Mail ID: <a href="mailto:ahiraalearning.helpline@gmail.com" style={{color:'white', textDecoration:'none'}}>ahiraalearning.helpline@gmail.com</a>
  </p>
  <p style={{ fontSize: '16px', marginBottom: '0' }}>
    For any doubts, please contact us at: 9390808501
  </p>
</div>

    <br/>
    
    
    
      </div>

      <DetailsPage />

      {/* <div>
        <div className="eeebg5 ">
          <div>
            <h6 className="text-center my-2 white-text">Students can choose the plan that best fits their learning preferences and career goals.</h6>
          </div>
          <div className='ForCenter'>
            <div class="row">
              <div class="col-md-4">
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <SocialMedia />
    </div>
  )
}

export default Coding;
