import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Content.css';

function Content() {
  const navigate = useNavigate();

  const handleApplyNowClick = () => {
    navigate('/details');  // Assuming '/details' is the route for the DetailsPage component
  };

  return (
    <div className='MBg'>
      <div className='Mtext'>
        <h1 className='mh1'>
          Rather than focusing solely on academic pursuits, equip yourself with practical skills and real-world experiences to thrive in the future.
        <br/>
        <button onClick={handleApplyNowClick} className='apply-now-button'>
          Apply Now
        </button> </h1>
      </div>
    </div>
  );
}

export default Content;
