import React, { useEffect, useState, useRef } from 'react';
import './AnimatedCounters.css'; // Assuming your CSS file is named AnimatedCounters.css
import { faUserGraduate, faUserTie, faCode, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Counter = ({ end, isPercentage, showPlus }) => {
  const [count, setCount] = useState(0);
  const [hasStarted, setHasStarted] = useState(false);
  const counterRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasStarted) {
          setHasStarted(true);
        }
      },
      { threshold: 0.5 }
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, [hasStarted]);

  useEffect(() => {
    if (hasStarted) {
      let start = 0;
      const duration = 4000;
      const stepTime = Math.abs(Math.floor(duration / end));

      const timer = setInterval(() => {
        start += 5;
        setCount(start);
        if (start >= end) clearInterval(timer);
      }, stepTime);

      return () => clearInterval(timer);
    }
  }, [end, hasStarted]);

  return (
    <h2 className="counter" ref={counterRef}>
      {count}{showPlus && '+'}{isPercentage && '%'}
    </h2>
  );
};

const AnimatedCounters = () => {
  return (
    <div className="text-center123">
      <div className="container">
        <div className="row justify-content-center py-5">
          <h2 className='text-center p-5'>Current Operational Status of the Organization</h2>
          <div className="col-6 col-md-3 mb-4">
            <div className="counter-box">
              <FontAwesomeIcon icon={faUserGraduate} className="counter-icon" style={{ color: 'blue' }} />
              <Counter end={1000} showPlus={true} />
              <p style={{ color: 'blue' }}><strong>Students</strong></p>
            </div>
          </div>
          <div className="col-6 col-md-3 mb-4">
            <div className="counter-box">
              <FontAwesomeIcon icon={faUserTie} className="counter-icon" style={{ color: 'purple' }} />
              <Counter end={50} showPlus={true} />
              <p style={{ color: 'purple' }}><strong>Top Professors</strong></p>
            </div>
          </div>
          <div className="col-6 col-md-3 mb-4">
            <div className="counter-box">
              <FontAwesomeIcon icon={faCode} className="counter-icon" style={{ color: 'red' }} />
              <Counter end={35} showPlus={true} />
              <p style={{ color: 'red' }}><strong>IT Experts</strong></p>
            </div>
          </div>
          <div className="col-6 col-md-3 mb-4">
            <div className="counter-box">
              <FontAwesomeIcon icon={faCheckCircle} className="counter-icon" style={{ color: 'green' }} />
              <Counter end={100} isPercentage={true} />
              <p style={{ color: 'green' }}><strong>Success Rate</strong> </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnimatedCounters;
