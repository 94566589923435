import React from 'react'
import { Link } from 'react-router-dom';
import './EEEPrice.css'

function EEEPrice() {
  const originalPrice = 3700; // Original price in currency units
  const discountPercentage = 25;
  const discountedPrice = originalPrice * (1 - discountPercentage / 100).toFixed(2);
  const originalPrice1 = 7500; // Original price in currency units
  const discountPercentage1 = 35;
  const discountedPrice1 = originalPrice1 * (1 - discountPercentage1 / 100).toFixed(2);
  const originalPrice2 = 12000; // Original price in currency units
  const discountPercentage2 = 45;
  const discountedPrice2 = originalPrice2 * (1 - discountPercentage2 / 100).toFixed(2);

  return (
    <div>
        <div className="EEEBGM12 ">
          <div>
          <h6 className="text-center" style={{color:'white', fontSize:'20px'}}>Students can choose the plan that best fits their learning preferences and career goals.</h6>
          </div>
          <div className='ForCenter'>
            <div class="row">
              <div class="col-md-4">
                <div class="plan-card self-led">
                  <h2 style={{color:'orange' }}>Self-led Plan</h2>
                  <ul>
                    <li>2+ hours of live sessions</li>
                    <li>Live doubt-clearing sessions</li>
                    <li>Recorded sessions for flexible learning</li>
                    <li>Real-time projects to apply learning</li>
                    <li>Portal access for up to 2 months</li>
                    <li>PDF materials for comprehensive study</li>
                    <li>Interview access and mock interview sessions</li>
                  </ul>
                  {/* <div  style={{ textAlign: 'center' }}><Link to={'/paymentloading'} className='EEEbtn btn btn-dark'>3,500/-</Link></div> */}
                  <div className="payment-container">
      <Link to={'/paymentloading'} className='payment-button-eee'>
      <span style={{fontSize:'12px'}}>Now  ₹ <span style={{fontSize:'16px'}}>3500/-&nbsp;&nbsp;</span><span style={{fontSize:'10px'}}><s>{discountedPrice.toFixed(2)}</s></span></span> <br/>
        <span style={{fontSize:'12px'}}>save <span style={{fontSize:'16px'}}>{discountPercentage}</span>% off on </span>         
        <span style={{fontSize:'14px' }}><s>₹{originalPrice}</s></span>
<span></span>
      </Link>
    </div>


                </div>
              </div>
              <div class="col-md-4">
                <div class="plan-card acknowledge-pre">
                  <h2 style={{color:'orange' }}>Acknowledge Pre Plan</h2>
                  <ul>
                    <li>4+ hours of live sessions with extended support</li>
                    <li>Live doubt-clearing sessions for individual attention</li>
                    <li>One-on-one discussions with mentors</li>
                    <li>Mentor guidance and interview access</li>
                    <li>Recorded sessions for flexible learning</li>
                    <li>Portal access for up to 4 months</li>
                    <li>PDF materials and LOR for career support</li>
                  </ul>
                  {/* <div  style={{ textAlign: 'center' }}><Link to={'/paymentloading'} className='EEEbtn btn btn-dark'>5,500/-</Link></div> */}
                  <div className="payment-container">
      <Link to={'/paymentloading'} className='payment-button-eee'>
      <span style={{fontSize:'12px'}}>Now  ₹ <span style={{fontSize:'16px'}}>5500/-&nbsp;&nbsp;</span><span style={{fontSize:'10px'}}><s>{discountedPrice1.toFixed(2)}</s></span></span> <br/>
        <span style={{fontSize:'12px'}}>save <span style={{fontSize:'16px'}}>{discountPercentage1}</span>% off on </span>         
        <span style={{fontSize:'14px' }}><s>₹{originalPrice1}</s></span>
<span></span>
      </Link>
    </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="plan-card advanced-mentor-pre">
                  <h2 style={{color:'orange' }}>Advanced Mentor Pre Plan</h2>
                  <ul>
                    <li>6+ hours of live sessions for in-depth learning</li>
                    <li>Live doubt-clearing sessions for individual attention</li>
                    <li>Recorded sessions for flexible learning</li>
                    <li>Portal access for up to 6 months</li>
                    <li>Mentor support and placement assistance</li>
                    <li>PDF materials and real-time projects</li>
                    <li>Interview access and LOR for career advancement</li>
                    <li>Live coding classes for practical skill development</li>
                  </ul>
                  {/* <div  style={{ textAlign: 'center' }}><Link to={'/paymentloading'} className='EEEbtn btn btn-dark'>7,500/-</Link></div> */}

                  <div className="payment-container">
      <Link to={'/paymentloading'} className='payment-button-eee'>
      <span style={{fontSize:'12px'}}>Now  ₹ <span style={{fontSize:'16px'}}>7500/-&nbsp;&nbsp;</span><span style={{fontSize:'10px'}}><s>{discountedPrice2.toFixed(2)}</s></span></span> <br/>
        <span style={{fontSize:'12px'}}>save <span style={{fontSize:'16px'}}>{discountPercentage2}</span>% off on </span>         
        <span style={{fontSize:'14px' }}><s>₹{originalPrice2}</s></span>
<span></span>
      </Link>
    </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default EEEPrice;
