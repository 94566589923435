import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const SummaryPage = () => {
    const location = useLocation();
    const { name, phoneNumber, email, college } = location.state || {};

    useEffect(() => {
        if (name && phoneNumber && email && college) {
            const formData = { name, phoneNumber, email, college };

            const sendDataToDatabase = async () => {
                try {
                    const response = await axios.post('http://localhost:3001/submit-details', formData);
                    if (response.status === 200) {
                        console.log('Data inserted successfully:', response.data);
                    } else {
                        console.error('Failed to insert data:', response.data);
                    }
                } catch (error) {
                    console.error('Error sending data:', error);
                }
            };

            sendDataToDatabase();
        }
    }, [name, phoneNumber, email, college]);

    return (
        <div className="summary-container">
            <div className="summary-content">
                <h2>Summary of Details</h2>
                <p><strong>Name:</strong> {name}</p>
                <p><strong>Phone Number:</strong> {phoneNumber}</p>
                <p><strong>Email:</strong> {email}</p>
                <p><strong>College:</strong> {college}</p>
            </div>
        </div>
    );
};

export default SummaryPage;
